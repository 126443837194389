import { Translate } from "components/common/Translate/Translate";
import { DesktopNavLink } from "./DesktopNavLink/DesktopNavLink";

interface DesktopNavigationProps {
    isEditor: boolean;
}

export const DesktopNavigation = ({ isEditor }: DesktopNavigationProps) => {
    return (
        <ul className="hidden lg:flex items-center ml-auto">
            <DesktopNavLink
                title={<Translate keyName={"links:image-resizer"} />}
                link="/"
                isEditor={isEditor}
                urlMatcher={(url) => {
                    return url === "/resize/editor/[task-id]";
                }}
            />
            <DesktopNavLink
                title={<Translate keyName={"image-cropper"} />}
                link="/crop-image"
                isEditor={isEditor}
                urlMatcher={(url) => url === "/crop-image/editor"}
            />
            <DesktopNavLink
                title={<Translate keyName={"image-compressor"} />}
                link="/image-compressor"
                isEditor={isEditor}
                urlMatcher={(url) => url === "/image-compressor/editor"}
            />
            <DesktopNavLink
                title={<Translate keyName={"bulk-resize"} />}
                link="/bulk-resize"
                isEditor={isEditor}
                urlMatcher={(url) => url === "/bulk-resize/editor"}
            />
            <DesktopNavLink
                title={
                    <span className="capitalize">
                        <Translate keyName={"more"} />
                    </span>
                }
                link="/"
                subItems={[
                    {
                        title: <Translate keyName="meme-generator" />,
                        link: "/meme-generator",
                    },
                    {
                        title: <Translate keyName="color-picker" />,
                        link: "/color-picker",
                    },
                    {
                        title: <Translate keyName="rotate-image" />,
                        link: "/rotate-image",
                    },
                    {
                        title: <Translate keyName="flip-image" />,
                        link: "/flip-image",
                    },
                    {
                        title: <Translate keyName="image-enlarger" />,
                        link: "/image-enlarger",
                        urlMatcher: (url) => url === "/image-enlarger/editor",
                    },
                ]}
                isEditor={isEditor}
            />
        </ul>
    );
};
