import { CloseIcon } from "components/common/Icons/CloseIcon";
import { HamburgerIcon } from "components/common/Icons/HamburgerIcon";
import { IRLogo } from "components/common/Icons/IRLogo";
import { Modal } from "components/common/Modal/Modal";
import { MobileNavLink } from "./MobileNavLink/MobileNavLink";
import { useToggle } from "client/hooks/utils/useToggle";
import { translateFunc } from "client/utils/translateFunc";
import type { LanguageProps } from "components/modules/Layout/Footer/Footer";
import { capitalize } from "client/utils/capitalize";
import { MobileTheme } from "./MobileTheme/MobileTheme";
import { MobileLanguage } from "./MobileLanguage/MobileLanguage";

interface MobileNavigationProps extends LanguageProps {
    isEditor: boolean;
}

export const MobileNavigation = ({ isEditor, url, urlSlugVariables }: MobileNavigationProps) => {
    const { isOpen, open: openModal, onClose: closeModal } = useToggle(false);

    return (
        <div className="lg:hidden">
            <div className="cursor-pointer overflow-hidden mr-2" onClick={openModal}>
                <HamburgerIcon />
            </div>
            <Modal isOpen={isOpen} close={closeModal}>
                <div className="w-full h-full bg-slate-900/50" onClick={closeModal}>
                    <div
                        className="w-full bg-white dark:bg-darkSurface-100 z-50 text-black"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* header */}
                        <div className="flex items-center p-4 border-b-slate-200/100 dark:border-b-darkSurface-300 border-b">
                            <IRLogo isEditor={isEditor} />
                            <div className="ml-auto cursor-pointer" onClick={closeModal}>
                                <CloseIcon />
                            </div>
                        </div>
                        <ul className="flex flex-col items-center ml-auto">
                            <MobileNavLink
                                title={translateFunc("links:image-resizer")}
                                link="/"
                                isEditor={isEditor}
                                urlMatcher={(url) => {
                                    return url.includes("/resize/editor");
                                }}
                            />
                            <MobileNavLink
                                title={translateFunc("links:image-cropper")}
                                link="/crop-image"
                                isEditor={isEditor}
                                urlMatcher={(url) => url === "/crop-image/editor"}
                            />
                            <MobileNavLink
                                title={translateFunc("links:image-compressor")}
                                link="/image-compressor"
                                isEditor={isEditor}
                                urlMatcher={(url) => url === "/image-compressor/editor"}
                            />
                            <MobileNavLink
                                title={translateFunc("links:bulk-resize")}
                                link="/bulk-resize"
                                isEditor={isEditor}
                                urlMatcher={(url) => url === "/bulk-resize/editor"}
                            />
                            <MobileNavLink
                                title={capitalize(translateFunc("links:more"))}
                                link="/"
                                isEditor={isEditor}
                                subItems={[
                                    {
                                        title: translateFunc("links:meme-generator"),
                                        link: "/meme-generator",
                                    },
                                    {
                                        title: translateFunc("links:color-picker"),
                                        link: "/color-picker",
                                    },
                                    {
                                        title: translateFunc("links:rotate-image"),
                                        link: "/rotate-image",
                                    },
                                    {
                                        title: translateFunc("links:flip-image"),
                                        link: "/flip-image",
                                    },
                                    {
                                        title: translateFunc("links:image-enlarger"),
                                        link: "/image-enlarger",
                                        urlMatcher: (url) => url === "/image-enlarger/editor",
                                    },
                                ]}
                            />
                            <MobileTheme />
                            <MobileLanguage url={url} urlSlugVariables={urlSlugVariables} />
                        </ul>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
