import { Translate } from "components/common/Translate/Translate";
import { useTheme } from "client/contexts/ThemeProvider";
import { SunIcon } from "components/common/Icons/theme/SunIcon";
import { MoonIcon } from "components/common/Icons/theme/MoonIcon";
import { ArrowIcon } from "components/common/Icons/ArrowIcon";
import { useToggle } from "client/hooks/utils/useToggle";
import { MonitorIcon } from "components/common/Icons/theme/MonitorIcon";
import { translateFunc } from "client/utils/translateFunc";

export const MobileTheme = () => {
    const { appliedTheme, setTheme, theme } = useTheme();
    const { isOpen, toggle } = useToggle();
    return (
        <li className="w-full py-4 flex flex-col gap-2 px-6 text-black dark:text-white border-t border-t-slate-200 dark:border-t-darkSurface-400">
            <div className="flex gap-2 items-center" onClick={toggle}>
                <span className="capitalize">
                    <Translate keyName="common:change-theme" />
                </span>
                {isOpen ? (
                    <span className="rotate-180">
                        <ArrowIcon />
                    </span>
                ) : (
                    <ArrowIcon />
                )}
                <span className="ml-auto">
                    {appliedTheme === "light" ? (
                        <SunIcon className="stroke-sky-500" />
                    ) : (
                        <MoonIcon className="fill-sky-500" />
                    )}
                </span>
            </div>
            {isOpen && (
                <div>
                    <button
                        className="w-full flex items-center gap-2 pl-2 pr-16 py-2 hover:bg-irGray-200 dark:hover:bg-darkSurface-300"
                        onClick={() => setTheme?.("light")}
                    >
                        <div>
                            <SunIcon
                                className={theme === "light" ? "stroke-sky-500" : "stroke-slate-500 dark:stroke-white"}
                            />
                        </div>
                        <div className={`capitalize ${theme === "light" ? "text-sky-500" : ""}`}>
                            {translateFunc("common:light")}
                        </div>
                    </button>
                    <button
                        className="w-full flex items-center gap-2 pl-2 pr-16 py-2 hover:bg-irGray-200 dark:hover:bg-darkSurface-300"
                        onClick={() => setTheme?.("dark")}
                    >
                        <div>
                            <MoonIcon
                                className={theme === "dark" ? "fill-sky-500" : "fill-slate-500 dark:fill-white"}
                            />
                        </div>
                        <div className={`capitalize ${theme === "dark" ? "text-sky-500" : ""}`}>
                            {translateFunc("common:dark")}
                        </div>
                    </button>
                    <button
                        className="w-full flex items-center gap-2 pl-2 pr-16 py-2 hover:bg-irGray-200 dark:hover:bg-darkSurface-300"
                        onClick={() => setTheme?.("system")}
                    >
                        <div>
                            <MonitorIcon
                                className={theme === "system" ? "stroke-sky-500" : "stroke-slate-900 dark:stroke-white"}
                            />
                        </div>
                        <div className={`capitalize ${theme === "system" ? "text-sky-500" : ""}`}>
                            {translateFunc("common:system")}
                        </div>
                    </button>
                </div>
            )}
        </li>
    );
};
