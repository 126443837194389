import { useRouter } from "next/router";
import translatedRoutedSlugs from "translatedRouteSlugs";
import { ArrowIcon } from "../../../../common/Icons/ArrowIcon";
import { DesktopNavLinkProps } from "../../DesktopNavigation/DesktopNavLink/DesktopNavLink";
import { useDesktopNavLink } from "../../DesktopNavigation/DesktopNavLink/useDesktopNavLink";
import { NavLinkParent } from "../../NavLinkParent/NavLinkParent";

interface MobileNavLinkProps {
    title: string;
    link: string;
    isEditor: boolean;
    outlink?: boolean;
    subItems?: NavChild[];
    isChild?: boolean;
    urlMatcher?: DesktopNavLinkProps["urlMatcher"];
}

interface NavChild {
    title: string;
    link: string;
    outlink?: boolean;
    urlMatcher?: DesktopNavLinkProps["urlMatcher"];
}

export const MobileNavLink = ({
    link,
    title,
    isEditor,
    subItems,
    isChild = false,
    outlink,
    urlMatcher,
}: MobileNavLinkProps) => {
    const { isFocused, setIsFocused, isActive } = useDesktopNavLink(subItems, isChild, link, urlMatcher);
    const hasChildren = subItems && subItems.length > 0;
    const { locale } = useRouter();
    link = link === "/" ? "/" : "/" + (translatedRoutedSlugs as any)[link]?.[locale ?? "en"] ?? link;

    return (
        <li onClick={() => hasChildren && setIsFocused(!isFocused)} className="w-full">
            <NavLinkParent
                link={link}
                isChild={isChild}
                isParent={!!hasChildren}
                outlink={outlink}
                twClasses={`${!isChild && `border-b-slate-200 border-b dark:border-b-darkSurface-300`}`}
                isEditor={isEditor}
            >
                <div className={`whitespace-nowrap ml-2 ${isActive ? "font-semibold" : ""}`}>{title}</div>
                {subItems &&
                    subItems.length &&
                    (isFocused ? (
                        <div className="ml-2 rotate-180">
                            <ArrowIcon />
                        </div>
                    ) : (
                        <div className="ml-2">
                            <ArrowIcon />
                        </div>
                    ))}
            </NavLinkParent>
            {hasChildren && isFocused && (
                <ul className="flex flex-col justify-start py-4 ml-8">
                    {subItems &&
                        subItems.map((item, index) => {
                            return (
                                <MobileNavLink
                                    link={item.link}
                                    title={item.title}
                                    isChild={true}
                                    outlink={item.outlink}
                                    key={index}
                                    isEditor={isEditor}
                                    urlMatcher={item.urlMatcher}
                                />
                            );
                        })}
                </ul>
            )}
        </li>
    );
};
