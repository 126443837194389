import { useToggle } from "client/hooks/utils/useToggle";
import { translateFunc } from "client/utils/translateFunc";
import Link from "next/link";
import { ReactNode } from "react";
import { RedirectConfirmationModal } from "./RedirectConfirmationModal/RedirectConfirmationModal";

interface NavLinkParentProps {
    link: string;
    isParent: boolean;
    isChild: boolean;
    isEditor: boolean;
    outlink?: boolean;
    twClasses?: string;
    children: ReactNode;
}

export const NavLinkParent = ({
    isParent,
    isChild,
    link,
    isEditor,
    twClasses,
    children,
    outlink,
}: NavLinkParentProps) => {
    const { isOpen, onClose: close, open } = useToggle();
    if (isParent) {
        return (
            <button
                className={`cursor-pointer rounded-md overflow-hidden w-full px-4 py-${
                    isChild ? "4" : "4"
                } flex items-center text-black dark:text-white dark:lg:hover:bg-darkSurface-300`}
                data-testid={`${link}-parent`}
                tabIndex={0}
                aria-label={translateFunc("common:open-sub-menu")}
            >
                {children}
            </button>
        );
    }

    return (
        <>
            <Link href={link} passHref>
                <a
                    className={`rounded-md overflow-hidden w-full px-3 py-${isChild ? "4" : "4"} flex items-center ${
                        twClasses ?? ""
                    } ${isChild && "lg:pr-24 lg:pl-6"} text-black dark:text-white dark:lg:hover:bg-darkSurface-300`}
                    onClick={(e) => {
                        if (!isEditor || e.metaKey || e.ctrlKey) {
                            return;
                        }

                        e.preventDefault();
                        e.stopPropagation();

                        open();
                    }}
                    target={!!outlink ? "_blank" : "_self"}
                    rel={!!outlink ? "nonreferrer" : ""}
                >
                    {children}
                </a>
            </Link>
            <RedirectConfirmationModal link={link} isOpen={isOpen} close={close} />
        </>
    );
};
